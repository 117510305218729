import Styled from 'styled-components';

const Container = Styled.div`
    padding: 20px;
`

const ButtonContainer = Styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 50px;

`

const ErrorContainer = Styled.div`
    background-color: #CDFADB;
    color: #FF204E;
    border-radius: 10px;
    padding: 15px;

    font-size: 18px;
`

export { Container, ErrorContainer, ButtonContainer };