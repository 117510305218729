import { Modal } from "components/modals/antd-modals";
import { useTranslation } from "react-i18next";

import { CertificateApi } from 'api/api';
import { useEffect, useState } from "react";
import { Button } from "components/buttons/buttons";

const certificateApi = new CertificateApi();

interface Props {
    isVisible: boolean;
    onHide: () => void;
    serialNumber: string;
}

const ViewCertificateModal: React.FC<Props> = ({
    isVisible,
    onHide,
    serialNumber
}) => {
    const { t } = useTranslation();
    const [pdfBlobUrl, setPdfBlobUrl] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {

        if (!serialNumber) {
            setPdfBlobUrl(null);
            return;
        }
        const fetchPdfData = async () => {
            setIsLoading(true);
            try {
                const languageCode = localStorage.getItem("i18nextLng");
                const response = await certificateApi.apiCertificateValidatePost({ validateCertificateCommand: { languageCode, serialNumber } });
                const base64 = response.data as any;
                setPdfBlobUrl(base64.pdfBase64);
            } catch (error) {
                console.error("Error fetching or processing the PDF: ", error);
            } finally {
                setIsLoading(false);
            }
        };


        fetchPdfData();
    }, [serialNumber]);

    return <Modal
        title={t("view-certificate:title", "View Certificate")
        }
        visible={isVisible}
        onCancel={onHide}
        width={"50%"}
        type="primary"
        footer={[<Button
            size="default"
            type="primary"
            onClick={onHide}
        >
            {t("view-certificate:button.close", "Close")}
        </Button>]}
    >
        {isLoading ? <p>Loading...</p> : <div style={{ height: '70vh' }}><embed src={`data:application/pdf;base64,${pdfBlobUrl}`} width={"100%"} height={"100%"} /></div>}

    </Modal>
}

export default ViewCertificateModal;