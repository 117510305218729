import { Card, Form, Input, PageHeader, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/buttons/buttons";
import { CardToolbox, ProfilePageheaderStyle } from "container/styled";
import { Main } from "pages/style";
import { SubTitle } from "pages/business-module/style";

import { CertificateApi, CodebookApi, LanguagesApi } from "api/api";
import { useDownloadBase64AsPdf } from "hooks/useDownloadBase64AsPdf";
import openNotificationWithIcon from "utility/notification";
import { Cards } from "components/cards/frame/cards-frame";

const certificateApi = new CertificateApi();
const codebookApi = new CodebookApi();
const languagesApi = new LanguagesApi();

const GenerateCertificatePage = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [reasonsData, setReasonsData] = useState([]);
    const [languages, setLanguages] = useState([]);

    const downloadBase64AsPdf = useDownloadBase64AsPdf();

    const handleSubmit = async (data: any) => {
        setIsLoading(true);
        try {
            const request = {
                nui: data.nui,
                reasonId: data.reasonId,
                personalNumber: data.personalNumber,
                languageCode: data.language,
            };
            const response = await certificateApi.apiCertificateGeneratePost({
                createCertificateCommand: request,
            });
            const base64 = response.data as any;
            setIsLoading(false);
            downloadBase64AsPdf(base64?.pdfBase64, `${base64?.serialNumber}.pdf`);
        } catch (error) {
            openNotificationWithIcon(
                "error",
                t(
                    "generate-certificate.error.title",
                    `Certificate failed to be generated. No business found with this number: {{dynamicValue}}.`,
                    { dynamicValue: data.businessId }
                )
            );
            // console.log("Certificate can");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchReasons = async () => {
            const response = await codebookApi.apiCodebookByTypeCodeGet({
                code: "REASONS",
            });
            const options = response.data.map((item) => ({
                value: item.id,
                label: item.name,
            }));
            setReasonsData(options as any);
        };

        fetchReasons();
    }, []);

    useEffect(() => {
        const fetchLanguages = async () => {
            const response = await languagesApi.apiLanguagesForUiGet();
            const options = response.data?.map((item) => ({
                value: item.code,
                label: item.name,
            }));
            setLanguages(options as any);
        };

        fetchLanguages();
    }, []);

    return (
        <Card>
            <Cards
                title={
                    <h3>
                        {t(
                            "generate-certificate.generate-certificate",
                            "Generate Certificate"
                        )}
                    </h3>
                }
            >
                <Main
                    style={{
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        width: "80%",
                        alignContent: "center",
                    }}
                >
                    <Form
                        name="resetPassword"
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                        requiredMark
                    >
                        <SubTitle>
                            Informata rreth gjenerimit te certifikates. Lorem Ipsum is simply
                            dummy text of the printing and typesetting industry. Lorem Ipsum
                            has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to
                            make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting.
                        </SubTitle>
                        <Form.Item
                            name="nui"
                            label={t("generate-certificate:lable.businessId", "Business ID")}
                            required
                            requiredMark
                            rules={[
                                {
                                    required: true,
                                    message: t("generate-certificate:lable.required.businessId", {
                                        field: t(
                                            "generate-certificate:lable.businessId",
                                            "Buseiness ID"
                                        ),
                                        defaultValue: "{{field}} is required!",
                                    }),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    "generate-certificate:lable.businessId-placeholder",
                                    "AA053GH"
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            name="personalNumber"
                            label={t(
                                "generate-certificate:lable.personalNumber",
                                "Personal Number"
                            )}
                            required
                            requiredMark
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        "generate-certificate:lable.required.personalNumber",
                                        {
                                            field: t(
                                                "generate-certificate:lable.businessId",
                                                "Personal Number"
                                            ),
                                            defaultValue: "{{field}} is required!",
                                        }
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    "generate-certificate:lable.personalNumber-placeholder",
                                    "Personal Number"
                                )}
                            />
                        </Form.Item>

                        <Form.Item
                            name="reasonId"
                            label={t("generate-certificate:lable.reasonId", "Reason")}
                            required
                            requiredMark
                            rules={[
                                {
                                    required: true,
                                    message: t("generate-certificate:lable.required.reasonId", {
                                        field: t("generate-certificate:lable.reasonId", "Reason"),
                                        defaultValue: "{{field}} is required!",
                                    }),
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder={t(
                                    "generate-certificate:lable.selectReason",
                                    "Select Reason"
                                )}
                                size="large"
                                options={reasonsData}
                            />
                        </Form.Item>

                        <Form.Item
                            name="language"
                            label={t(
                                "generate-certificate:lable.language",
                                "Certificate Language"
                            )}
                            required
                            requiredMark
                            rules={[
                                {
                                    required: true,
                                    message: t("generate-certificate:lable.required.language", {
                                        field: t(
                                            "generate-certificate:lable.language",
                                            "Certificate Language"
                                        ),
                                        defaultValue: "{{field}} is required!",
                                    }),
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                placeholder={t(
                                    "generate-certificate:lable.select-language",
                                    "Select Language"
                                )}
                                size="large"
                                options={languages}
                            />
                        </Form.Item>
                        <Button
                            size="large"
                            type="info"
                            htmlType="submit"
                            loading={isLoading}
                            style={{
                                width: "100%",
                                marginTop: 50,
                            }}
                        >
                            {isLoading
                                ? t("generate-certificate.buttonTextGenerating", "Generating")
                                : t("generate-certificate.buttonText", "Generate")}
                        </Button>
                    </Form>
                </Main>
            </Cards>
        </Card>
    );
};

export default GenerateCertificatePage;
