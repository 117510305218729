import { TFunction } from "i18next";

export const codebookClaimNames: Record<any, string> = {
  ["TypeOfVehicles"]: "type-of-vehicles",
};

export const getCodebookTextsByType = (
  t: TFunction<"translation", undefined>,
  label: any
) => {
  const claimName = label;
  const moduleName = `common-list-${claimName}`;

  const texts = {
    label,
    title: t(`${moduleName}:title`, claimName) as any,
    add: t(`${moduleName}:add`, `Add ${claimName}`),
    deleteConfirm: t(
      `${moduleName}.delete-confirmation`,
      "Are you sure you want to delete?"
    ),
    editModalTitle: t(`${moduleName}:edit-modal-title`, `Edit ${claimName}`),
    addModalTitle: t(`${moduleName}:add-modal-title`, `Add ${claimName}`),
    editedSuccessfully: t(
      `${moduleName}:success-edit`,
      `${claimName} updated successfully!`
    ),
    addedSuccessfully: t(
      `${moduleName}:success-add`,
      `${claimName} created successfully!`
    ),
  };

  return texts;
};
