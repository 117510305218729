/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcy App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActivityLogDto } from '../models';
// @ts-ignore
import { TranslationDto } from '../models';
/**
 * TranslationsApi - axios parameter creator
 * @export
 */
export const TranslationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsGet: async (pageNumber?: number, pageSize?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {Array<TranslationDto>} [translationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsKeyPut: async (key: string, translationDto?: Array<TranslationDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiTranslationsKeyPut', 'key', key)
            const localVarPath = `/api/Translations/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsLanguageGet: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiTranslationsLanguageGet', 'language', language)
            const localVarPath = `/api/Translations/{Language}`
                .replace(`{${"Language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} lng 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsLngPost: async (lng: string, requestBody?: { [key: string]: string; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('apiTranslationsLngPost', 'lng', lng)
            const localVarPath = `/api/Translations/{lng}`
                .replace(`{${"lng"}}`, encodeURIComponent(String(lng)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsGet(pageNumber?: number, pageSize?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLogDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsGet(pageNumber, pageSize, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {Array<TranslationDto>} [translationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsKeyPut(key: string, translationDto?: Array<TranslationDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsKeyPut(key, translationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsLanguageGet(language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsLanguageGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} lng 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTranslationsLngPost(lng: string, requestBody?: { [key: string]: string; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTranslationsLngPost(lng, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsGet(pageNumber?: number, pageSize?: number, search?: string, options?: any): AxiosPromise<Array<ActivityLogDto>> {
            return localVarFp.apiTranslationsGet(pageNumber, pageSize, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {Array<TranslationDto>} [translationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsKeyPut(key: string, translationDto?: Array<TranslationDto>, options?: any): AxiosPromise<object> {
            return localVarFp.apiTranslationsKeyPut(key, translationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsLanguageGet(language: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiTranslationsLanguageGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} lng 
         * @param {{ [key: string]: string; }} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTranslationsLngPost(lng: string, requestBody?: { [key: string]: string; }, options?: any): AxiosPromise<object> {
            return localVarFp.apiTranslationsLngPost(lng, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTranslationsGet operation in TranslationsApi.
 * @export
 * @interface TranslationsApiApiTranslationsGetRequest
 */
export interface TranslationsApiApiTranslationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TranslationsApiApiTranslationsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof TranslationsApiApiTranslationsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof TranslationsApiApiTranslationsGet
     */
    readonly search?: string
}

/**
 * Request parameters for apiTranslationsKeyPut operation in TranslationsApi.
 * @export
 * @interface TranslationsApiApiTranslationsKeyPutRequest
 */
export interface TranslationsApiApiTranslationsKeyPutRequest {
    /**
     * 
     * @type {string}
     * @memberof TranslationsApiApiTranslationsKeyPut
     */
    readonly key: string

    /**
     * 
     * @type {Array<TranslationDto>}
     * @memberof TranslationsApiApiTranslationsKeyPut
     */
    readonly translationDto?: Array<TranslationDto>
}

/**
 * Request parameters for apiTranslationsLanguageGet operation in TranslationsApi.
 * @export
 * @interface TranslationsApiApiTranslationsLanguageGetRequest
 */
export interface TranslationsApiApiTranslationsLanguageGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TranslationsApiApiTranslationsLanguageGet
     */
    readonly language: string
}

/**
 * Request parameters for apiTranslationsLngPost operation in TranslationsApi.
 * @export
 * @interface TranslationsApiApiTranslationsLngPostRequest
 */
export interface TranslationsApiApiTranslationsLngPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TranslationsApiApiTranslationsLngPost
     */
    readonly lng: string

    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TranslationsApiApiTranslationsLngPost
     */
    readonly requestBody?: { [key: string]: string; }
}

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     * 
     * @param {TranslationsApiApiTranslationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsGet(requestParameters: TranslationsApiApiTranslationsGetRequest = {}, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslationsApiApiTranslationsKeyPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsKeyPut(requestParameters: TranslationsApiApiTranslationsKeyPutRequest, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsKeyPut(requestParameters.key, requestParameters.translationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslationsApiApiTranslationsLanguageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsLanguageGet(requestParameters: TranslationsApiApiTranslationsLanguageGetRequest, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsLanguageGet(requestParameters.language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TranslationsApiApiTranslationsLngPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public apiTranslationsLngPost(requestParameters: TranslationsApiApiTranslationsLngPostRequest, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).apiTranslationsLngPost(requestParameters.lng, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
