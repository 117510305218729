import { useState, useEffect } from 'react';

export const useIsMobileDevice = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const mobileDeviceRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
        setIsMobile(mobileDeviceRegex.test(userAgent));
    }, []);

    return isMobile;
}
