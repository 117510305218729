import { Col, Input, Row, Table } from "antd";
import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ActivityLogsApi } from "../../api";
/**
 * Hooks and Constants
 */
import { sortDirections } from "constants/constants";
import { useAuthorization } from "hooks/useAuthorization";

/**
 * Styles
 */
import {
  CardToolbox,
  Main,
  ProfilePageheaderStyle,
  TableWrapper,
} from "container/styled";
import { UserTableStyleWrapper } from "../style";

/**
 * Components
 */
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { Modal } from "components/modals/antd-modals";
import { PageHeader } from "components/page-headers/page-headers";
import { useTableSorting } from "hooks/useTableSorting";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import OrdinalNumber from "components/common/OrdinalNumber";

const logsApi = new ActivityLogsApi();

const ReportsPage = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * Authorization
   */
  const { hasPermission } = useAuthorization();

  /**
   * States
   */
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [reports, setReports] = useState({
    totalCount: 0,
    pageIndex: 1,
    items: [],
  });
  const { onSorterChange, sorting } = useTableSorting();
  const [query, setQuery] = useState({
    pageNumber: 1,
    pageSize: 10,
    fullName: "",
    ip: "",
    logType: 0,
    activity: "",
  });

  const logTypes = [
    { value: 0, label: t("reports.ALL", "ALL") },
    { value: 1, label: t("reports.INFO", "INFO") },
    { value: 2, label: t("reports.ERROR", "ERROR") },
    { value: 3, label: t("reports.EXCEPTION", "EXCEPTION") },
  ];

  const columns = [
    {
      title: t("reports.businessId", "Business ID"),
      dataIndex: "ip",
      key: "ip",
      sorter: true,
      sortDirections,
    },
    
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "70px",
    },
  ];

  /**
   * Generate table data
   */
  const generateTableData = () => {
    return reports?.items?.map((record) => {
      const {
        id,
        ordinalNumber,
        ip,
      } = record;

      return {
        key: id,
        ordinalNumber: <OrdinalNumber value={ordinalNumber} />,
        ip,
        action: (
          <div
            key={id}
            className="table-actions"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* {show this in a correct role } */}

            {/* {hasPermission("reports:view-details") && ( */}

              <>
                <Button
                  className="btn-icon"
                  type="primary"
                  to="#"
                  shape="circle"
                >
                  <Link
                    onClick={() => {
                      setDetailsModalVisible(true);
                      setSelectedLog(record);
                    }}
                    to="#"
                  >
                    <FeatherIcon icon="eye" size={16} />
                  </Link>
                </Button>
                <Button
                  className="btn-icon"
                  type="primary"
                  to="#"
                  shape="circle"
                >
                </Button>
              </>
            
          </div>
        ),
      };
    });
  };

  /**
   * Get reports
   */
  const getReports = async () => {
    const { data } = await logsApi.activitiesGet({ ...query, ...sorting });
    setReports(data);
    setLoading(false);
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
      getReports();
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [query, sorting]);

  /**
   * Tables Event
   */
  const handlePaginationChange = (pageNumber) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const handleShowSizeChange = (pageNumber, pageSize) => {
    setQuery((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  return (
    <>
      <CardToolbox>
        <ProfilePageheaderStyle>
          <PageHeader
            ghost
            title={t("reports.title", "Reports")}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={t("reports.title", "Reports")}
                data={generateTableData()}
                columns={[
                  "ordinalNumber",
                  "businessId",
                  "activity",
                ]}
              />,
            ]}
          />
        </ProfilePageheaderStyle>
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless s>
              <Row
                gutter={[15, 15]}
                justify="spaces-around"
                style={{ zIndex: 999 }}
              >
                <Col md={12}>
                  <Input
                    style={{ height: "38px" }}
                    placeholder={t(
                      "reports.search-business",
                      "Search Business"
                    )}
                    onChange={(e) => {
                      setQuery({
                        ...query,
                        fullName: e.target.value,
                        pageNumber: 1,
                      });
                    }}
                  />
                </Col>
                <Col md={6} style={{ zIndex: 999 }}>
                  <Select
                    placeholder={t("reports.bankruptcy-type", "Lloji i falementimit")}
                    styles={[{ zIndex: 999 }]}
                    options={logTypes}
                    onChange={(selectedOption) => {
                      setQuery({
                        ...query,
                        logType: selectedOption.value,
                        pageNumber: 1,
                      });
                    }}
                  />
                </Col>

                <Col md={6} style={{ zIndex: 999 }}>
                  <Select
                    placeholder={t("reports.type-of-decision", "Lloji i aktvendimit")}
                    styles={[{ zIndex: 999 }]}
                    options={logTypes}
                    onChange={(selectedOption) => {
                      setQuery({
                        ...query,
                        logType: selectedOption.value,
                        pageNumber: 1,
                      });
                    }}
                  />
                </Col>

              </Row>
            </Cards>
          </Col>
          <Col md={24}>
            <UserTableStyleWrapper>
              <Cards headless>
                <TableWrapper>
                  <Table
                    loading={isLoading}
                    dataSource={generateTableData()}
                    columns={columns}
                    showSorterTooltip={false}
                    pagination={{
                      position: ["bottomCenter"],
                      pageSize: query.pageSize,
                      total: reports.totalCount,
                      current: reports.pageIndex,
                      showSizeChanger: true,
                      pageSizeOptions: [10, 50, 100, 1000],
                      showTotal: (total) =>
                        `${t("reports.total", "Total")} ${total} ${t(
                          "reports.report",
                          "Report(s)"
                        )}`,
                      onChange: handlePaginationChange,
                      onShowSizeChange: handleShowSizeChange,
                    }}
                    onChange={(_, __, sorter) => onSorterChange(sorter)}
                  />
                </TableWrapper>
              </Cards>
            </UserTableStyleWrapper>
          </Col>
        </Row>
        <Modal
          type="primary"
          title={selectedLog?.activity}
          visible={isDetailsModalVisible}
          footer={null}
          onCancel={() => setDetailsModalVisible(false)}
          width="80%"
        >
          <span
            style={{ wordBreak: "break-all" }}
            dangerouslySetInnerHTML={{ __html: selectedLog?.description }}
          ></span>
        </Modal>
      </Main>
    </>
  );
};

export default ReportsPage;
