import { Form, Input, Row } from "antd";
import { CodebookApi, CodebookTypeApi } from "api/api";
import { Button } from "components/buttons/buttons";
import { Modal } from "components/modals/antd-modals";
import { AddProfile, BasicFormWrapper } from "container/styled";
import { getCodebookTextsByType } from "pages/codebooks/CodebookCommonPage/data";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import openNotificationWithIcon from "utility/notification";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { CodebookTypeDto } from "api/models";

interface CreateCommonCodebookModalProps {
  isVisible: boolean;
  codebookTypeToEdit?: CodebookTypeDto | null;
  currentOrder: Number | null;
  onHide: () => void;
  onSuccess: () => void;
}

const codebookApi = new CodebookTypeApi();

const defaultValues = {
  name: "",
};

export const CreateCodebookTypeModal = ({
  isVisible,
  onHide,
  onSuccess,
  codebookTypeToEdit,
  currentOrder,
}: CreateCommonCodebookModalProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const isEditMode = Boolean(codebookTypeToEdit);

  useEffect(() => {
    if (!codebookTypeToEdit) {
      form.setFieldsValue({
        order: currentOrder,
      });
      return;
    }

    const { id, name, order,code } = codebookTypeToEdit;

    form.setFieldsValue({
      id,
      name,
      order,
      code,
    });
  }, [codebookTypeToEdit, form]);

  const handleSubmit = async (values: any) => {
    try {
      const { id, name, order,code } = values;
      setLoading(true);

      if (isEditMode) {
        await codebookApi.apiCodebookTypesIdPut({
          id,
          updateCodebookTypeCommand: { id, name, order,code },
        });
      } else {
        await codebookApi.apiCodebookTypesPost({
          addCodebookTypeCommand: { name, order,code },
        });
      }

      openNotificationWithIcon(
        "success",
        isEditMode
          ? t(
              "codebook-type:updated-successfully",
              "List Type Updated Successfully"
            )
          : t(
              "codebook-type:added-successfully",
              "New List Was Successfully Created"
            )
      );

      form.resetFields();
      onSuccess();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      type={"primary"}
      title={
        isEditMode
          ? t("codebook-type:update", "Add new List")
          : t("codebook-type:add", "Add new List")
      }
      visible={isVisible}
      onCancel={onHide}
      footer={null}
    >
      <div className="project-modal">
        <AddProfile>
          <BasicFormWrapper>
            <Form
              requiredMark
              form={form}
              name={isEditMode ? "editInstitution" : "addInstitution"}
              onFinish={handleSubmit}
              initialValues={defaultValues}
            >
              <Form.Item name="id" hidden>
                <Input placeholder="Id" />
              </Form.Item>

              <Form.Item
                required
                requiredMark
                rules={[
                  {
                    required: true,
                    message: t("validations.required-field", {
                      field: t("global.order", "Order"),
                      defaultValue: "{{field}} is required!",
                    }),
                  },
                ]}
                name={"order"}
                label={t("global.order", "Order")}
              >
                <Input
                  required
                  type="number"
                  placeholder={t("global.order", "Order") + "*"}
                  prefix={<FeatherIcon icon="hashtag" size={14} />}
                />
              </Form.Item>

              <Form.Item
                required
                requiredMark
                rules={[
                  {
                    required: true,
                    message: t("validations.required-field", {
                      field: t("global.name", "Name"),
                      defaultValue: "{{field}} is required!",
                    }),
                  },
                ]}
                name={"name"}
                label={t("global.name", "Name")}
              >
                <Input
                  required
                  placeholder={t("global.name", "Name") + "*"}
                  prefix={<FeatherIcon icon="user" size={14} />}
                />
              </Form.Item>

              <Form.Item
                required
                requiredMark
                rules={[
                  {
                    required: true,
                    message: t("validations.required-field", {
                      field: t("global.code", "Code"),
                      defaultValue: "{{field}} is required!",
                    }),
                  },
                ]}
                name={"code"}
                label={t("global.code", "Code")}
              >
                <Input
                  required
                  placeholder={t("global.code", "Code") + "*"}
                  prefix={<FeatherIcon icon="user" size={14} />}
                />
              </Form.Item>

              <Row justify="end">
                <Button
                  htmlType="submit"
                  size="default"
                  loading={loading}
                  type="primary"
                  key="submit"
                >
                  {isEditMode
                    ? t("global:edit", "Edit")
                    : t("global:add", "Add")}
                </Button>
              </Row>
            </Form>
          </BasicFormWrapper>
        </AddProfile>
      </div>
    </Modal>
  );
};
