import { Outlet } from "react-router-dom";
import { Layout } from "antd";

const { Content } = Layout;

const PublicLayout = () => {
    return (
        <Layout
            className="atbd-main-layout"
            style={{ minHeight: "100vh", backgroundColor: "white" }}
        >
            <Content>
                <Outlet />
            </Content>
        </Layout>
    );
};

export default PublicLayout;
