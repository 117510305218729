import { Avatar } from "antd";
import FeatherIcon from "feather-icons-react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Heading from "../../heading/heading";
import { Popover } from "../../popup/popup";
import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { UsersApi } from "api/api";

import { Language } from "./language";

const userApi = new UsersApi();

function AuthInfo() {
  const navigate = useNavigate();

  const userName = JSON.parse(localStorage.getItem("user"))?.userName;

  const SignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    navigate("/login");
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <figcaption>
            <Heading as="h5">{userName || "John Doe"}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admin/profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut}>
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      {/* <Message /> */}
      {/* <Notification /> */}

      {/* <Settings /> */}
      {/* <Support /> */}
      <div className="nav-author">
        <Language userApi={userApi} />
      </div>

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
}

export default AuthInfo;
