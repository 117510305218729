import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { Dropdown } from "components/dropdown/dropdown";
import { NavAuth } from "components/utilities/auth-info/auth-info-style";

import { theme } from "config/theme/themeVariables";
import { useTranslation } from "react-i18next";
import { LanguagesApi } from "api/api";

const languagesApi = new LanguagesApi();

export const Language = ({ userApi, showLangCode = true, color = "#5B5F7C" }: any) => {
  const { i18n } = useTranslation();

  const [languages, setLanguages] = useState<any[]>([]);

  const getLanguages = async () => {
    const { data } = await languagesApi.apiLanguagesGet();
    const lng = localStorage.getItem("i18nextLng");

    setLanguages(data);

    if (lng === "en-US") {
      // eslint-disable-next-line array-callback-return
      data?.map((res: any) => {
        if (res.isDefault) {
          i18n.changeLanguage(res.i18nCode?.code || "");
        }
      });
    }
  };

  const onFlagChangeHandle = (value: any) => {
    try {
      i18n.changeLanguage(value);
      const languageObj =
        languages.find((x) => x?.i18nCode?.code === value) || null;

      if (!languageObj) return;

      userApi.usersSetDefaultLanguagePut({
        updateLanguageRequest: { languageId: languageObj?.id },
      });
    } catch (err) { }
  };

  const country = (
    <NavAuth>
      {languages.map((language: any) => {
        const isDefaultLanguage = language?.i18nCode?.code === i18n?.language;
        return (
          <Link
            onClick={() => onFlagChangeHandle(language?.i18nCode?.code)}
            to="#"
            key={language?.i18nCode?.code}
            style={{
              backgroundColor: isDefaultLanguage ? theme["linkActive"] : "",
            }}
          >
            <span className={isDefaultLanguage ? "ant-menu-item-selected" : ""}>
              {language?.i18nCode?.name}
            </span>
          </Link>
        );
      })}
    </NavAuth>
  );

  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Dropdown placement="bottomRight" content={country}>
      <Link
        to="#"
        className="head-example"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <FeatherIcon icon="globe" color={color} size={24} />
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg> */}
        {
          showLangCode && <p style={{ fontWeight: 500, margin: 0, paddingLeft: 5 }}>
            {localStorage.getItem("i18nextLng")?.toUpperCase()}
          </p>
        }

      </Link>
    </Dropdown>
  );
};
