import { Col, Input, Table } from "antd";
import { CertificateApi, CertificateApiApiCertificateDownloadedAllGetRequest } from "api/api";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  CardToolbox,
  Main,
  ProfilePageheaderStyle,
  TableWrapper,
} from "container/styled";
import ValidateCertificateModal from "pages/certificate-module/ViewCertificateModal";
import { CertificateLog, CertificateLogsResponse, useDownloadedCertificateColumns } from "pages/certificate-module/downloadedCertificateTableColumns";
import { UserTableStyleWrapper } from "pages/style";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const certificateApi = new CertificateApi();

const DownloadedCertificatePage = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  const [certificateData, setCertificateData] = useState<CertificateLogsResponse>();

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalsState, setModalsState] = useState<any>({ addModalVisible: false, serialNumber: null });

  const [request, setRequest] =
    useState<CertificateApiApiCertificateDownloadedAllGetRequest>({
      search: "",
      pageNumber: 1,
      pageSize: 10,
    });

  const handleViewClick = (item: any) => {
    setModalsState({ addModalVisible: true, serialNumber: item.serialNumber });
  }

  const columns = useDownloadedCertificateColumns({ handleViewClick });

  useEffect(() => {
    getAllDownloadedCertificate();
  }, [request, searchQuery]);

  /**
  * Functions
  */
  const getAllDownloadedCertificate = async () => {
    setIsLoading(true);
    try {
      const response = await certificateApi.apiCertificateDownloadedAllGet({ pageNumber: request.pageNumber, pageSize: request.pageSize, search: searchQuery });

      setCertificateData(response.data as CertificateLogsResponse);

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  const handlePaginationChange = (pageNumber: number) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  const closeModal = () => {
    setModalsState({ addModalVisible: false, serialNumber: null });
  };

  return (
    <>
      <CardToolbox>
        <ProfilePageheaderStyle>
          <PageHeader
            ghost
            title={t("downloaded-certificate.title", "List of downloaded certificates")}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={t("downloaded-certificate.title", "Downloaded_Certificates")}
                data={certificateData?.items}
                exportCSV={false}
                exportPDF={false}
                columns={columns.map(res => res?.key)}
                translateKey="certificate-downloaded-history:table:"
              />,
            ]}
          />
        </ProfilePageheaderStyle>
      </CardToolbox>
      <Main>
        <Col md={24}>
          <Cards headless>
            <Col md={6}>
              <Input
                style={{ height: "38px" }}
                placeholder={t("downloaded-certificate.search", "Search businesses")}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
          </Cards>
        </Col>
        <Col md={24}>
          <UserTableStyleWrapper>
            <Cards headless>
              <TableWrapper>
                <Table
                  dataSource={certificateData?.items}
                  columns={columns}
                  loading={isLoading}
                  showSorterTooltip={false}
                  pagination={{
                    current: certificateData?.pageIndex,
                    total: certificateData?.totalCount,
                    showSizeChanger: true,
                    pageSizeOptions: [10, 50, 100, 1000],
                    onChange: handlePaginationChange,
                    onShowSizeChange: onShowSizeChange,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                />
              </TableWrapper>
            </Cards>
          </UserTableStyleWrapper>
        </Col>
        <ValidateCertificateModal isVisible={modalsState.addModalVisible} onHide={closeModal} serialNumber={modalsState.serialNumber} />
      </Main>
    </>
  );
};

export default DownloadedCertificatePage;
