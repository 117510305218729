/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcy App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateCertificateCommand } from '../models';
// @ts-ignore
import { CreateCertificateDto } from '../models';
// @ts-ignore
import { PaginatedCertificatesDto } from '../models';
// @ts-ignore
import { ValidateCertificateCommand } from '../models';
/**
 * CertificateApi - axios parameter creator
 * @export
 */
export const CertificateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateBusinessIdGet: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('apiCertificateBusinessIdGet', 'businessId', businessId)
            const localVarPath = `/api/certificate/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateDownloadedAllGet: async (search?: string, pageNumber?: number, pageSize?: number, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificate/downloaded/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCertificateCommand} [createCertificateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateGeneratePost: async (createCertificateCommand?: CreateCertificateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificate/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCertificateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} personalNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateUserPersonalNumberGet: async (personalNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalNumber' is not null or undefined
            assertParamExists('apiCertificateUserPersonalNumberGet', 'personalNumber', personalNumber)
            const localVarPath = `/api/certificate/user/{personalNumber}`
                .replace(`{${"personalNumber"}}`, encodeURIComponent(String(personalNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateCertificateCommand} [validateCertificateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateValidatePost: async (validateCertificateCommand?: ValidateCertificateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificate/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateCertificateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertificateApi - functional programming interface
 * @export
 */
export const CertificateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertificateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificateBusinessIdGet(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateCertificateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificateBusinessIdGet(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificateDownloadedAllGet(search?: string, pageNumber?: number, pageSize?: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCertificatesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificateDownloadedAllGet(search, pageNumber, pageSize, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCertificateCommand} [createCertificateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificateGeneratePost(createCertificateCommand?: CreateCertificateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificateGeneratePost(createCertificateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} personalNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificateUserPersonalNumberGet(personalNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreateCertificateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificateUserPersonalNumberGet(personalNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateCertificateCommand} [validateCertificateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificateValidatePost(validateCertificateCommand?: ValidateCertificateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificateValidatePost(validateCertificateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CertificateApi - factory interface
 * @export
 */
export const CertificateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertificateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateBusinessIdGet(businessId: string, options?: any): AxiosPromise<Array<CreateCertificateDto>> {
            return localVarFp.apiCertificateBusinessIdGet(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateDownloadedAllGet(search?: string, pageNumber?: number, pageSize?: number, startDate?: string, endDate?: string, options?: any): AxiosPromise<PaginatedCertificatesDto> {
            return localVarFp.apiCertificateDownloadedAllGet(search, pageNumber, pageSize, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCertificateCommand} [createCertificateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateGeneratePost(createCertificateCommand?: CreateCertificateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertificateGeneratePost(createCertificateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} personalNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateUserPersonalNumberGet(personalNumber: string, options?: any): AxiosPromise<Array<CreateCertificateDto>> {
            return localVarFp.apiCertificateUserPersonalNumberGet(personalNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateCertificateCommand} [validateCertificateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificateValidatePost(validateCertificateCommand?: ValidateCertificateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCertificateValidatePost(validateCertificateCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCertificateBusinessIdGet operation in CertificateApi.
 * @export
 * @interface CertificateApiApiCertificateBusinessIdGetRequest
 */
export interface CertificateApiApiCertificateBusinessIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertificateApiApiCertificateBusinessIdGet
     */
    readonly businessId: string
}

/**
 * Request parameters for apiCertificateDownloadedAllGet operation in CertificateApi.
 * @export
 * @interface CertificateApiApiCertificateDownloadedAllGetRequest
 */
export interface CertificateApiApiCertificateDownloadedAllGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertificateApiApiCertificateDownloadedAllGet
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof CertificateApiApiCertificateDownloadedAllGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof CertificateApiApiCertificateDownloadedAllGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof CertificateApiApiCertificateDownloadedAllGet
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof CertificateApiApiCertificateDownloadedAllGet
     */
    readonly endDate?: string
}

/**
 * Request parameters for apiCertificateGeneratePost operation in CertificateApi.
 * @export
 * @interface CertificateApiApiCertificateGeneratePostRequest
 */
export interface CertificateApiApiCertificateGeneratePostRequest {
    /**
     * 
     * @type {CreateCertificateCommand}
     * @memberof CertificateApiApiCertificateGeneratePost
     */
    readonly createCertificateCommand?: CreateCertificateCommand
}

/**
 * Request parameters for apiCertificateUserPersonalNumberGet operation in CertificateApi.
 * @export
 * @interface CertificateApiApiCertificateUserPersonalNumberGetRequest
 */
export interface CertificateApiApiCertificateUserPersonalNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CertificateApiApiCertificateUserPersonalNumberGet
     */
    readonly personalNumber: string
}

/**
 * Request parameters for apiCertificateValidatePost operation in CertificateApi.
 * @export
 * @interface CertificateApiApiCertificateValidatePostRequest
 */
export interface CertificateApiApiCertificateValidatePostRequest {
    /**
     * 
     * @type {ValidateCertificateCommand}
     * @memberof CertificateApiApiCertificateValidatePost
     */
    readonly validateCertificateCommand?: ValidateCertificateCommand
}

/**
 * CertificateApi - object-oriented interface
 * @export
 * @class CertificateApi
 * @extends {BaseAPI}
 */
export class CertificateApi extends BaseAPI {
    /**
     * 
     * @param {CertificateApiApiCertificateBusinessIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public apiCertificateBusinessIdGet(requestParameters: CertificateApiApiCertificateBusinessIdGetRequest, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).apiCertificateBusinessIdGet(requestParameters.businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertificateApiApiCertificateDownloadedAllGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public apiCertificateDownloadedAllGet(requestParameters: CertificateApiApiCertificateDownloadedAllGetRequest = {}, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).apiCertificateDownloadedAllGet(requestParameters.search, requestParameters.pageNumber, requestParameters.pageSize, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertificateApiApiCertificateGeneratePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public apiCertificateGeneratePost(requestParameters: CertificateApiApiCertificateGeneratePostRequest = {}, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).apiCertificateGeneratePost(requestParameters.createCertificateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertificateApiApiCertificateUserPersonalNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public apiCertificateUserPersonalNumberGet(requestParameters: CertificateApiApiCertificateUserPersonalNumberGetRequest, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).apiCertificateUserPersonalNumberGet(requestParameters.personalNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CertificateApiApiCertificateValidatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertificateApi
     */
    public apiCertificateValidatePost(requestParameters: CertificateApiApiCertificateValidatePostRequest = {}, options?: AxiosRequestConfig) {
        return CertificateApiFp(this.configuration).apiCertificateValidatePost(requestParameters.validateCertificateCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
