import { Button, Col, Form, Input, Row, Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  BasicFormWrapper,
  CardToolbox,
  Main,
  TableWrapper,
} from "container/styled";
import { useAuthorization } from "hooks/useAuthorization";
import openNotificationWithIcon from "utility/notification";
import { EmailOptionsApi } from "../../../api";
import { Modal } from "../../../components/modals/antd-modals";
import { UserTableStyleWrapper } from "../../style";

const emailOptionsApi = new EmailOptionsApi();

function hasErrors(fieldErrors) {
  let has_errors = false;

  for (let i = 0; i < fieldErrors.length; i++) {
    const error = fieldErrors[i];
    const { errors } = error;

    if (errors.length > 0) {
      has_errors = true;
    }
  }

  return has_errors;
}

const EmailPage = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();

  const [tableColumns] = useState([
    {
      title: t("configure.email.from", "Email (From)"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("configure.email.smtpHost", "SMTP Host"),
      dataIndex: "smtpHost",
      key: "smtpHost",
    },
    {
      title: t("configure.email.smtpPass", "SMTP Password"),
      dataIndex: "smtpPass",
      key: "smtpPass",
      border: "1px solid red",
    },
    {
      title: t("configure.email.smtpUser", "SMTP User Account"),
      dataIndex: "smtpUser",
      key: "smtpUser",
      border: "1px solid red",
    },
    {
      title: "",
    },
  ]);

  const [emailData, setEmailData] = useState([]);
  const emailTableData = [];
  const [form] = Form.useForm();

  const [addEmailConfiguration, setAddEmailConfiguration] = useState({
    isLoading: false,
    modalVisible: false,
    testedConnections: false,
    testConnectionsError: false,
    testConnectionsLoading: false,
  });

  const handleSubmit = async (values) => {
    try {
      if (!addEmailConfiguration.testedConnections) {
        handleTestConnection();
        return;
      }
      setAddEmailConfiguration((p) => ({ ...p, isLoading: true }));
      await emailOptionsApi.emailOptionsPost({
        createNewEmailOptionsCommand: values,
      });
      openNotificationWithIcon(
        "success",
        t(
          "email.options.success.add",
          "Email Configuration was created successfully"
        )
      );
      setAddEmailConfiguration((p) => ({
        ...p,
        isLoading: false,
        modalVisible: false,
      }));
      getEmailOptions();
    } catch (error) {}
  };

  const handleTestConnection = async () => {
    try {
      const errors = form.getFieldsError();
      if (hasErrors(errors)) {
        return;
      }

      setAddEmailConfiguration((p) => ({
        ...p,
        testConnectionsError: false,
        testConnectionsLoading: true,
      }));

      const values = form.getFieldsValue();

      const response = await emailOptionsApi.emailOptionsTestConnectionPost({
        testSmtpConnectionCommand: {
          email: values?.from,
          password: values?.smtpPass,
          port: values?.smtpPort,
          smtpServer: values?.smtpHost,
          username: values?.smtpUser,
        },
      });

      setAddEmailConfiguration((p) => ({
        ...p,
        testedConnections: response?.data?.success,
        testConnectionsLoading: false,
      }));
    } catch (error) {
      setAddEmailConfiguration((p) => ({
        ...p,
        testConnectionsError: true,
        testConnectionsLoading: false,
      }));
    }
  };

  const getEmailOptions = async () => {
    setAddEmailConfiguration((p) => ({ ...p, isLoading: true }));
    const { data } = await emailOptionsApi.emailOptionsActiveGet();
    setEmailData([data]);
    setAddEmailConfiguration((p) => ({ ...p, isLoading: false }));
  };

  useEffect(() => {
    getEmailOptions();
  }, []);

  emailData?.map((emailObj) => {
    const { from, smtpHost, smtpUser } = emailObj;
    return emailTableData.push({
      from,
      smtpHost,
      smtpPass: "************",
      smtpUser,
    });
  });

  return (
    <div>
      <CardToolbox>
        <PageHeader
          key={"email-page-header"}
          ghost
          title={t("email-options.title", "SMTP Email Config")}
          buttons={[
            hasPermission("email-options:add") && (
              <Button
                onClick={() =>
                  setAddEmailConfiguration((p) => ({
                    ...p,
                    modalVisible: true,
                  }))
                }
                className="btn-add_new"
                size="default"
                type="primary"
                key="add-email-config-modal"
              >
                {t("email-configurations.update", "Update Email Configuration")}
              </Button>
            ),
          ]}
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col sm={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <TableWrapper>
                  <Table
                    style={{ textAlign: "left" }}
                    pagination={false}
                    loading={addEmailConfiguration.isLoading}
                    dataSource={emailTableData}
                    columns={tableColumns}
                    rowKey={(record) => record.id}
                    scroll={{ x: "max-content" }}
                  />
                </TableWrapper>
              </UserTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>

      <Modal
        type="primary"
        title={t("email:title", "Update Email Configuration")}
        footer={null}
        visible={addEmailConfiguration.modalVisible}
        onCancel={() =>
          setAddEmailConfiguration((p) => ({ ...p, modalVisible: false }))
        }
      >
        {addEmailConfiguration.isLoading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: 320,
            }}
          />
        ) : (
          <BasicFormWrapper>
            <Form
              id="addEmailConfig"
              form={form}
              name="email"
              onFinish={handleSubmit}
              requiredMark
            >
              <Form.Item
                required
                name="from"
                rules={[
                  {
                    required: true,
                    message: "Send From Email!",
                    type: "email",
                  },
                ]}
              >
                <Input type="email" placeholder="Email" required />
              </Form.Item>
              <Form.Item
                required
                name="smtpHost"
                rules={[{ required: true, message: "SMTP Host is required" }]}
              >
                <Input placeholder="SMTP Host ex: smtp.gmail.com" required />
              </Form.Item>
              <Form.Item
                name="smtpPort"
                required
                rules={[{ required: true, message: "SMTP Port" }]}
              >
                <Input type="number" placeholder="SMTP Port" required />
              </Form.Item>
              <Form.Item
                name="smtpUser"
                required
                rules={[{ required: true, message: "SMTP User" }]}
              >
                <Input placeholder="SMTP Account User" required />
              </Form.Item>
              <Form.Item
                name="smtpPass"
                required
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: "SMTP Password" }]}
              >
                <Input placeholder="SMTP Password" type="password" required />
              </Form.Item>
              <div
                style={{
                  paddingTop: 20,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                }}
              >
                <div style={{ textAlign: "right" }}>
                  {addEmailConfiguration.testedConnections && (
                    <div style={{ color: "#4347d9" }}>
                      {t(
                        "email-options.email-test-connection-success",
                        "Connection was successful!"
                      )}
                    </div>
                  )}
                  {addEmailConfiguration.testConnectionsError && (
                    <div style={{ color: "red" }}>
                      {t(
                        "errors.email-test-connection-failed",
                        "Connection with the server failed, please check your credentials!"
                      )}
                    </div>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {!addEmailConfiguration.testedConnections ? (
                    <Button
                      htmlType="submit"
                      size="default"
                      type="light"
                      key="test-connection"
                      load={addEmailConfiguration.testConnectionsLoading}
                      disabled={addEmailConfiguration.testConnectionsLoading}
                    >
                      {addEmailConfiguration.testConnectionsLoading && <Spin />}
                      {t("email.test-connection", "Test Connection")}
                    </Button>
                  ) : null}

                  {addEmailConfiguration.testedConnections ? (
                    <Button
                      form="addEmailConfig"
                      htmlType="submit"
                      size="default"
                      type="primary"
                      key="add-email-config"
                    >
                      {t("email.save-as-default", "Save as default")}
                    </Button>
                  ) : null}
                </div>
              </div>
            </Form>
          </BasicFormWrapper>
        )}
      </Modal>
    </div>
  );
};

export default EmailPage;
