/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcyApp PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import { RequiredError, RequestArgs } from "./base";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import axiosIntercept from "api/axiosIntercept";

/**
 *
 * @export
 */
export const DUMMY_BASE_URL = "https://example.com";

/**
 *
 * @throws {RequiredError}
 * @export
 */
export const assertParamExists = function (
  functionName: string,
  paramName: string,
  paramValue: unknown
) {
  if (paramValue === null || paramValue === undefined) {
    throw new RequiredError(
      paramName,
      `Required parameter ${paramName} was null or undefined when calling ${functionName}.`
    );
  }
};

const parseJwt = (token: any) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/**
 *
 * @export
 */
let pendingRefreshRequest: Promise<any> | null = null;

export const setApiKeyToObject = async function (
  object: any,
  keyParamName: string,
  configuration?: Configuration
) {
  let user = JSON.parse(localStorage.getItem("user") as any);

  if (!user) return;

  let { accessToken, rememberMe } = user;

  // Check if access token has expired
  let decodedToken = parseJwt(accessToken);
  let now = Math.floor(new Date().getTime() / 1000);
  if (now >= decodedToken.exp && rememberMe) {
    const basePath =
      window.location.hostname === "localhost"
        ? "https://localhost:7111"
        : "/".replace(/\/+$/, "");

    if (!pendingRefreshRequest) {
      // Call refresh token endpoint and store the promise
      pendingRefreshRequest = axios.post(`${basePath}/api/auth/refresh-token`, {
        accessToken,
        rememberMe,
      });
    }

    let response;
    try {
      response = await pendingRefreshRequest;
    } catch (err) {
      // Reset pending promise queue
      pendingRefreshRequest = null;
      throw err; // rethrow the error
    }

    // Reset pending promise queue
    pendingRefreshRequest = null;

    if (response.status === 200) {
      let { accessToken: newAccessToken, rememberMe: newRembemerMe } =
        response.data;

      // Update local storage with new access token and expiry time
      user.accessToken = newAccessToken;
      user.rememberMe = newRembemerMe;

      localStorage.setItem("user", JSON.stringify(user));
      accessToken = newAccessToken;
    }
  }

  // Set Authorization header
  if (accessToken) object["Authorization"] = "Bearer " + accessToken;
};

function setFlattenedQueryParams(
  urlSearchParams: URLSearchParams,
  parameter: any,
  key: string = ""
): void {
  if (typeof parameter === "object") {
    if (Array.isArray(parameter)) {
      (parameter as any[]).forEach((item) =>
        setFlattenedQueryParams(urlSearchParams, item, key)
      );
    } else {
      Object.keys(parameter).forEach((currentKey) =>
        setFlattenedQueryParams(
          urlSearchParams,
          parameter[currentKey],
          `${key}${key !== "" ? "." : ""}${currentKey}`
        )
      );
    }
  } else {
    if (urlSearchParams.has(key)) {
      urlSearchParams.append(key, parameter);
    } else {
      urlSearchParams.set(key, parameter);
    }
  }
}

/**
 *
 * @export
 */
export const setSearchParams = function (url: URL, ...objects: any[]) {
  const searchParams = new URLSearchParams(url.search);
  setFlattenedQueryParams(searchParams, objects);
  url.search = searchParams.toString();
};

/**
 *
 * @export
 */
export const serializeDataIfNeeded = function (
  value: any,
  requestOptions: any,
  configuration?: Configuration
) {
  const nonString = typeof value !== "string";
  const needsSerialization =
    nonString && configuration && configuration.isJsonMime
      ? configuration.isJsonMime(requestOptions.headers["Content-Type"])
      : nonString;
  return needsSerialization
    ? JSON.stringify(value !== undefined ? value : {})
    : value || "";
};

/**
 *
 * @export
 */
export const toPathString = function (url: URL) {
  return url.pathname + url.search + url.hash;
};

/**
 *
 * @export
 */
export const createRequestFunction = function (
  axiosArgs: RequestArgs,
  globalAxios: AxiosInstance,
  BASE_PATH: string,
  configuration?: Configuration
) {
  return <T = unknown, R = AxiosResponse<T>>(
    axios: AxiosInstance = globalAxios,
    basePath: string = BASE_PATH
  ) => {
    axiosIntercept(globalAxios);
    const axiosRequestArgs = {
      ...axiosArgs.options,
      url: (configuration?.basePath || basePath) + axiosArgs.url,
    };
    return axios.request<T, R>(axiosRequestArgs);
  };
};
