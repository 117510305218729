import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Button } from "components/buttons/buttons";
import { ColumnsType } from "antd/lib/table";
import { hasPermission } from "utility/accessibility/hasPermission";

export interface CertificateLog {
  ordinalNumber: number;
  id: string;
  businessId: string;
  certificateType: string;
  generatedDate: string;
  name: string;
  lastName: string;
  personalNumber: string;
  serialNumber: string;
  waterMark: null | string; // Assuming waterMark can be either string or null
}

export interface CertificateLogsResponse {
  items: CertificateLog[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

interface ActionHandlers {
  handleViewClick: (record: CertificateLog) => void;
}

export const useDownloadedCertificateColumns = ({
  handleViewClick,
}: ActionHandlers): ColumnsType<CertificateLog> => {
  const { t } = useTranslation();

  return [
    {
      title: t("certificate-downloaded-history:table:ordinalNumber", "Nr."),
      dataIndex: "ordinalNumber",
      key: "ordinalNumber",
      sorter: false,
    },
    {
      title: t("certificate-downloaded-history:table:businessId", "BusinessId"),
      dataIndex: "businessId",
      key: "businessId",
      sorter: false,
    },
    {
      title: t(
        "certificate-downloaded-history:table:businessName",
        "Business Name"
      ),
      dataIndex: "businessName",
      key: "businessName",
      sorter: false,
    },
    {
      title: t(
        "certificate-downloaded-history:table:purposeOfParticipation",
        "Qëllimi i pjesëmarrjes"
      ),
      dataIndex: "reasonCode",
      key: "reasonCode",
      sorter: false,
    },
    {
      title: t(
        "certificate-downloaded-history:table:dateOfGenerated",
        "Data e Gjenerimit"
      ),
      dataIndex: "generatedDate",
      key: "generatedDate",
      sorter: (a, b) => {
        const dateA = moment(a.generatedDate, "YYYY-MM-DD");
        const dateB = moment(b.generatedDate, "YYYY-MM-DD");

        return dateA.diff(dateB);
      },
      sortDirections: ["descend", "ascend", "descend"],
      render: (_, record) => (
        <p>{moment(record.generatedDate).format("DD-MM-YYYY HH:mm")}</p>
      ),
    },
    {
      title: t("certificate-downloaded-history:table:action", "Action"),
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <>
          {hasPermission("certificates:view") ? (
            <div className="table-actions">
              <Button
                className="btn-icon"
                type="info"
                shape="circle"
                onClick={() => handleViewClick(record)}
              >
                <FeatherIcon icon="eye" size={25} />
              </Button>
            </div>
         ) : null} 
        </>
      ),
    },
  ];
};
