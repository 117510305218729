import Styled from 'styled-components';


export const SubTitle = Styled.p`
    font-size: 16px;
    color: #707C92;
`
export const TextSeconday = Styled.p`
    font-size: 14px;
    color: #707C92;
`
export const TextPrimery = Styled.p`
    color: #0F245A;
    font-size: 18px;
`