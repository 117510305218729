import React, { useEffect, useState } from "react";
import { Col, Input, Row, Skeleton, Table } from "antd";
import {
  BankruptcyCasesApi,
  BankruptcyCasesApiApiBankruptcyCasesGetRequest,
} from "api/api";

import { Cards } from "components/cards/frame/cards-frame";
import {
  CardToolbox,
  Main,
  ProfilePageheaderStyle,
  TableWrapper,
} from "container/styled";
import { UserTableStyleWrapper } from "pages/style";
import { useTranslation } from "react-i18next";
import { PageHeader } from "components/page-headers/page-headers";
import { ColumnsType } from "antd/lib/table";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";

const bunkruptcyCasesApi = new BankruptcyCasesApi();

const BankruptcyPage = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * States
   */
  const [bunkruptcyData, setBunkruptcyData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);

  const [request, setRequest] =
    useState<BankruptcyCasesApiApiBankruptcyCasesGetRequest>({
      searchTerm: "",
      pageNumber: 1,
      pageSize: 10,
    });

  const columns: ColumnsType<any> = [
    {
      title: t("bankruptcy.table.ordinalNumber", "Ordinal Number"),
      dataIndex: "ordinalNumber",
      key: "ordinalNumber",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.businessName", "Business Name"),
      dataIndex: "businessName",
      key: "businessName",
      sorter: false,
    },
    {
      title: t(
        "bankruptcy.table.responsiblePeronName",
        "Responsible Person Name"
      ),
      dataIndex: "responsiblePeronName",
      key: "responsiblePeronName",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.responsiblePeronID", "Responsible Person ID"),
      dataIndex: "responsiblePeronID",
      key: "responsiblePeronID",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.bankruptcyType", "Bankruptcy Type"),
      dataIndex: "bankruptcyType",
      key: "bankruptcyType",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.caseNumber", "Case Number"),
      dataIndex: "caseNumber",
      key: "caseNumber",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.caseType", "Case Type"),
      dataIndex: "caseType",
      key: "caseType",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.court", "Court"),
      dataIndex: "court",
      key: "court",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.decisionDate", "Decision Date"),
      dataIndex: "decisionDate",
      key: "decisionDate",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.fiscalNumber", "Fiscal Number"),
      dataIndex: "fiscalNumber",
      key: "fiscalNumber",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.initiationDate", "Initiation Date"),
      dataIndex: "initiationDate",
      key: "initiationDate",
      sorter: false,
    },
    {
      title: t("bankruptcy.table.initiationType", "Initiation Type"),
      dataIndex: "initiationType",
      key: "initiationType",
      sorter: false,
    },
    {
      title: t(
        "bankruptcy.table.initiatorPersonalNumber",
        "Initiator Personal Number"
      ),
      dataIndex: "initiatorPersonalNumber",
      key: "initiatorPersonalNumber",
      sorter: false,
    },
  ];

  /**
   * Functions
   */
  const handleGetBunkruptcyCases = async () => {
    setIsLoading(true);
    const response = await bunkruptcyCasesApi.apiBankruptcyCasesGet({
      searchTerm: request.searchTerm,
      pageNumber: request.pageNumber,
      pageSize: request.pageSize,
    });
    const data = response.data as any;
    setBunkruptcyData(data);
    setIsLoading(false);
  };

  const handlePaginationChange = (pageNumber: number) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber: number, pageSize: number) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  useEffect(() => {
    handleGetBunkruptcyCases();
  }, [request]);

  const isInitialLoading = isLoading && !bunkruptcyData?.items;

  return (
    <>
      <CardToolbox>
        <ProfilePageheaderStyle>
          <PageHeader
            ghost
            title={t("bankruptcy.title", "Bankruptcy List")}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={t("bankruptcy.title", "Bankruptcy")}
                data={bunkruptcyData?.items}
                exportCSV={false}
                exportPDF={false}
                columns={columns.map(res => res?.key)}
                translateKey="bankruptcy.table."
                // columns={[
                //   "ordinalNumber",
                //   "businessName",
                //   "responsiblePeronName",
                //   "responsiblePeronID",
                //   "bankruptcyType",
                //   "caseNumber",
                //   "caseType",
                //   "court",
                //   "decisionDate",
                //   "fiscalNumber",
                //   "initiationDate",
                //   "initiationType",
                //   "initiatorPersonalNumber",
                // ]}
              />,
            ]}
          />
        </ProfilePageheaderStyle>
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <Row gutter={12}>
                <Col md={12}>
                  <Input
                    style={{ height: "38px" }}
                    placeholder={t(
                      "bankruptcy.search",
                      "Search..."
                    )}
                    onChange={(e) =>
                      setRequest((prev) => ({
                        ...prev,
                        searchTerm: e.target.value,
                      }))
                    }
                  />
                </Col>
              </Row>
            </Cards>
          </Col>
          <Col md={24}>
            <UserTableStyleWrapper>
              <Cards headless>
                <TableWrapper>
                  {isInitialLoading ? (
                    <Cards headless>
                      <Skeleton active paragraph={{ rows: 5 }} />
                    </Cards>
                  ) : (
                    <Table
                      dataSource={bunkruptcyData?.items}
                      columns={columns}
                      showSorterTooltip={false}
                      scroll={{ x: "max-content" }}
                      pagination={{
                        current: bunkruptcyData?.pageIndex,
                        total: bunkruptcyData?.totalCount,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 50, 100, 1000],
                        onChange: handlePaginationChange,
                        onShowSizeChange: onShowSizeChange,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  )}
                </TableWrapper>
              </Cards>
            </UserTableStyleWrapper>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default BankruptcyPage;
