import { Suspense } from "react";
import { Spin } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppLayout } from "container/layouts/AppLayout";
import { AuthRoutes, MainRoutes, PublicRoutes } from "./Routes";
import AuthLayout from "container/layouts/AuthLayout";
import { CommonDataProvider } from "contexts/CommonDataContext/CommonDataProvider";
import BusinessLayout from "container/layouts/BusinessLayout";
import PublicLayout from "container/layouts/PublicLayout";

export const AppRouter = () => {
  const isLoggedIn = localStorage.getItem("user");
  if (
    !isLoggedIn &&
    !AuthRoutes.map((x) => x.path).includes(window.location.pathname) &&
    !PublicRoutes.some((route) => {
      const routeBase = route.path!.split("/:")[0];
      return window.location.pathname.startsWith(routeBase);
    })
  ) {
    window.location.href = "/login";
  }

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Routes>
          <Route element={<AuthLayout />}>
            {AuthRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Route>
          <Route
            element={
              <CommonDataProvider>
                <AppLayout />
              </CommonDataProvider>
            }
          >
            {MainRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Route>
          <Route
            element={
              <CommonDataProvider>
                <PublicLayout />
              </CommonDataProvider>
            }
          >
            {PublicRoutes.map((route, index) => (
              <Route key={index} {...route} />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
