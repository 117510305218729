/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcy App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActivityLogDto } from '../models';
/**
 * ActivityLogsApi - axios parameter creator
 * @export
 */
export const ActivityLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [fullName] 
         * @param {string} [iP] 
         * @param {number} [logType] 
         * @param {string} [activity] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGet: async (pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, fullName?: string, iP?: string, logType?: number, activity?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['FullName'] = fullName;
            }

            if (iP !== undefined) {
                localVarQueryParameter['IP'] = iP;
            }

            if (logType !== undefined) {
                localVarQueryParameter['LogType'] = logType;
            }

            if (activity !== undefined) {
                localVarQueryParameter['Activity'] = activity;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityLogsApi - functional programming interface
 * @export
 */
export const ActivityLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [fullName] 
         * @param {string} [iP] 
         * @param {number} [logType] 
         * @param {string} [activity] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activitiesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, fullName?: string, iP?: string, logType?: number, activity?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityLogDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activitiesGet(pageNumber, pageSize, sortBy, sortType, fullName, iP, logType, activity, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityLogsApi - factory interface
 * @export
 */
export const ActivityLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityLogsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [fullName] 
         * @param {string} [iP] 
         * @param {number} [logType] 
         * @param {string} [activity] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activitiesGet(pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, fullName?: string, iP?: string, logType?: number, activity?: string, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<Array<ActivityLogDto>> {
            return localVarFp.activitiesGet(pageNumber, pageSize, sortBy, sortType, fullName, iP, logType, activity, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activitiesGet operation in ActivityLogsApi.
 * @export
 * @interface ActivityLogsApiActivitiesGetRequest
 */
export interface ActivityLogsApiActivitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly iP?: string

    /**
     * 
     * @type {number}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly logType?: number

    /**
     * 
     * @type {string}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly activity?: string

    /**
     * 
     * @type {string}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof ActivityLogsApiActivitiesGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * ActivityLogsApi - object-oriented interface
 * @export
 * @class ActivityLogsApi
 * @extends {BaseAPI}
 */
export class ActivityLogsApi extends BaseAPI {
    /**
     * 
     * @param {ActivityLogsApiActivitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityLogsApi
     */
    public activitiesGet(requestParameters: ActivityLogsApiActivitiesGetRequest = {}, options?: AxiosRequestConfig) {
        return ActivityLogsApiFp(this.configuration).activitiesGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.fullName, requestParameters.iP, requestParameters.logType, requestParameters.activity, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }
}
