/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcy App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddCodebookTypeCommand } from '../models';
// @ts-ignore
import { CodebookTypeDto } from '../models';
// @ts-ignore
import { UpdateCodebookTypeCommand } from '../models';
/**
 * CodebookTypeApi - axios parameter creator
 * @export
 */
export const CodebookTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesCodeCodeGet: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodebookTypesCodeCodeGet', 'code', code)
            const localVarPath = `/api/codebook-types/code/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codebook-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodebookTypesIdDelete', 'id', id)
            const localVarPath = `/api/codebook-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodebookTypesIdGet', 'id', id)
            const localVarPath = `/api/codebook-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCodebookTypeCommand} [updateCodebookTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesIdPut: async (id: string, updateCodebookTypeCommand?: UpdateCodebookTypeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodebookTypesIdPut', 'id', id)
            const localVarPath = `/api/codebook-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCodebookTypeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddCodebookTypeCommand} [addCodebookTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesPost: async (addCodebookTypeCommand?: AddCodebookTypeCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codebook-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCodebookTypeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodebookTypeApi - functional programming interface
 * @export
 */
export const CodebookTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodebookTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookTypesCodeCodeGet(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodebookTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookTypesCodeCodeGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodebookTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookTypesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookTypesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookTypesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodebookTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookTypesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCodebookTypeCommand} [updateCodebookTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookTypesIdPut(id: string, updateCodebookTypeCommand?: UpdateCodebookTypeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookTypesIdPut(id, updateCodebookTypeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddCodebookTypeCommand} [addCodebookTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodebookTypesPost(addCodebookTypeCommand?: AddCodebookTypeCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodebookTypesPost(addCodebookTypeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodebookTypeApi - factory interface
 * @export
 */
export const CodebookTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodebookTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesCodeCodeGet(code: string, options?: any): AxiosPromise<CodebookTypeDto> {
            return localVarFp.apiCodebookTypesCodeCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesGet(options?: any): AxiosPromise<Array<CodebookTypeDto>> {
            return localVarFp.apiCodebookTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodebookTypesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesIdGet(id: string, options?: any): AxiosPromise<CodebookTypeDto> {
            return localVarFp.apiCodebookTypesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCodebookTypeCommand} [updateCodebookTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesIdPut(id: string, updateCodebookTypeCommand?: UpdateCodebookTypeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodebookTypesIdPut(id, updateCodebookTypeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddCodebookTypeCommand} [addCodebookTypeCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodebookTypesPost(addCodebookTypeCommand?: AddCodebookTypeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodebookTypesPost(addCodebookTypeCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCodebookTypesCodeCodeGet operation in CodebookTypeApi.
 * @export
 * @interface CodebookTypeApiApiCodebookTypesCodeCodeGetRequest
 */
export interface CodebookTypeApiApiCodebookTypesCodeCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookTypeApiApiCodebookTypesCodeCodeGet
     */
    readonly code: string
}

/**
 * Request parameters for apiCodebookTypesIdDelete operation in CodebookTypeApi.
 * @export
 * @interface CodebookTypeApiApiCodebookTypesIdDeleteRequest
 */
export interface CodebookTypeApiApiCodebookTypesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookTypeApiApiCodebookTypesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for apiCodebookTypesIdGet operation in CodebookTypeApi.
 * @export
 * @interface CodebookTypeApiApiCodebookTypesIdGetRequest
 */
export interface CodebookTypeApiApiCodebookTypesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookTypeApiApiCodebookTypesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiCodebookTypesIdPut operation in CodebookTypeApi.
 * @export
 * @interface CodebookTypeApiApiCodebookTypesIdPutRequest
 */
export interface CodebookTypeApiApiCodebookTypesIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CodebookTypeApiApiCodebookTypesIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCodebookTypeCommand}
     * @memberof CodebookTypeApiApiCodebookTypesIdPut
     */
    readonly updateCodebookTypeCommand?: UpdateCodebookTypeCommand
}

/**
 * Request parameters for apiCodebookTypesPost operation in CodebookTypeApi.
 * @export
 * @interface CodebookTypeApiApiCodebookTypesPostRequest
 */
export interface CodebookTypeApiApiCodebookTypesPostRequest {
    /**
     * 
     * @type {AddCodebookTypeCommand}
     * @memberof CodebookTypeApiApiCodebookTypesPost
     */
    readonly addCodebookTypeCommand?: AddCodebookTypeCommand
}

/**
 * CodebookTypeApi - object-oriented interface
 * @export
 * @class CodebookTypeApi
 * @extends {BaseAPI}
 */
export class CodebookTypeApi extends BaseAPI {
    /**
     * 
     * @param {CodebookTypeApiApiCodebookTypesCodeCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookTypeApi
     */
    public apiCodebookTypesCodeCodeGet(requestParameters: CodebookTypeApiApiCodebookTypesCodeCodeGetRequest, options?: AxiosRequestConfig) {
        return CodebookTypeApiFp(this.configuration).apiCodebookTypesCodeCodeGet(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookTypeApi
     */
    public apiCodebookTypesGet(options?: AxiosRequestConfig) {
        return CodebookTypeApiFp(this.configuration).apiCodebookTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookTypeApiApiCodebookTypesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookTypeApi
     */
    public apiCodebookTypesIdDelete(requestParameters: CodebookTypeApiApiCodebookTypesIdDeleteRequest, options?: AxiosRequestConfig) {
        return CodebookTypeApiFp(this.configuration).apiCodebookTypesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookTypeApiApiCodebookTypesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookTypeApi
     */
    public apiCodebookTypesIdGet(requestParameters: CodebookTypeApiApiCodebookTypesIdGetRequest, options?: AxiosRequestConfig) {
        return CodebookTypeApiFp(this.configuration).apiCodebookTypesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookTypeApiApiCodebookTypesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookTypeApi
     */
    public apiCodebookTypesIdPut(requestParameters: CodebookTypeApiApiCodebookTypesIdPutRequest, options?: AxiosRequestConfig) {
        return CodebookTypeApiFp(this.configuration).apiCodebookTypesIdPut(requestParameters.id, requestParameters.updateCodebookTypeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CodebookTypeApiApiCodebookTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodebookTypeApi
     */
    public apiCodebookTypesPost(requestParameters: CodebookTypeApiApiCodebookTypesPostRequest = {}, options?: AxiosRequestConfig) {
        return CodebookTypeApiFp(this.configuration).apiCodebookTypesPost(requestParameters.addCodebookTypeCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
