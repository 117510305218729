import { CodebookTypeApi } from "api/api";
import { getClaims } from "utility/getClaims";
import { toDashedKey } from "utility/utility";
const codebookApi = new CodebookTypeApi();

async function getDynamicCodebookMenuItems(t: any) {
  const dynamicCodebooks = (await codebookApi.apiCodebookTypesGet())?.data ?? []; // Fetch from your API

  return dynamicCodebooks.map(codebook => ({
    key: toDashedKey(codebook.name), // Use label to generate the key
    label: t(`side-bar:configurations.codebook-types.${toDashedKey(codebook.name)}`, codebook.name),
    path: `/admin/codebook-types/${codebook.id}`,
    claimName: `${toDashedKey(codebook.name)}`,
  }));
}


export const menuItemsList = async (t: any) => {
  const dynamicCodebookSubMenuItems = await getDynamicCodebookMenuItems(t);

  return {
    dashboardLog: {
      key: 'dashboardLog',
      claimName:'dashboard',
      label: t("side-bar:dashboard", "Dashboard"),
      icon: 'home'
    },
    dashboard: {
      key: "dashboard",
      label: t("side-bar:administration", "Administration"),
      icon: "users",
      subMenuItems: [
        {
          key: "users",
          label: t("side-bar:administration.users", "Users"),
          path: "/admin/users",
          claimName: "users",
        },
        {
          key: "user-groups",
          label: t("side-bar:administration.user-groups", "User Groups"),
          path: "/admin/user-groups",
          claimName: "user-groups",
        },
      ],
    },
    bankruptcy: {
      key: "bankruptcy",
      label: t("side-bar:bankruptcy-list", "Bankruptcy List"),
      icon: "server",
      path: "/admin/businesses/bankrupted",
      claimName: "bankruptcy-cases:list",
      checkOnlyPermission: true
    },
    certificates: {
      key: "certificates",
      label: t("side-bar:certificates", "Certificates"),
      icon: "file-text",
      subMenuItems: [
        {
          key: "generateNewCertificate",
          label: t("side-bar:certificates.generate", "Generate New"),
          path: "/admin/certificates/generate",
          claimName: "certificates:list",
          checkOnlyPermission: true
        },
        {
          key: "downloadedCertificate",
          label: t("side-bar:certificates.downloaded", "Downloaded"),
          path: "/admin/certificates/downloaded",
          claimName: "certificates:list",
          checkOnlyPermission: true
        },
      ]
    },
    configurations: {
      key: "configurations",
      label: t("side-bar:configurations", "Configurations"),
      icon: "settings",
      subMenuItems: [
        {
          key: "email",
          label: t("side-bar:configurations.email", "Email"),
          path: "/admin/email",
          claimName: "email-options",
        },
        {
          key: "translations",
          label: t("side-bar:configurations.translations", "Translations"),
          path: "/admin/translations",
          claimName: "translations",
        },
        {
          key: "reasons-configurations",
          label: t("side-bar:configurations.reasons-configurations", "Reasons Configurations"),
          path: "/admin/reasons-configurations",
          claimName: "reasons-configurations",
        },
        {
          key: "languages",
          label: t("side-bar:configurations.languages", "Languages"),
          path: "/admin/languages",
          claimName: "languages",
        },
        // {
        //   key: "codebook-types",
        //   label: t("side-bar:configurations.codebook-types", "Codebook Types"),
        //   path: "/admin/codebook-types",
        //   claimName: "codebook-types",
        // },
      ],
    },
    codebooks: {
      key: "codebooks",
      label: t("side-bar:codebooks", "Codebooks"),
      icon: "file-text",
      subMenuItems: dynamicCodebookSubMenuItems,
    },
    logs: {
      key: "logs",
      label: t("side-bar:logs", "Logs"),
      path: "/admin/logs",
      icon: "rewind",
      claimName: "logs",
    },
    // reports: {
    //   key: "reports",
    //   label: t("side-bar:reports", "Reports"),
    //   path: "/admin/reports",
    //   icon: "pie-chart",
    //   // has to change
    //   claimName: "logs",
    // },
  }
};

export const getCurrentUserModules = (): string[] => {
  const claims = getClaims();
  const modules = claims.map((x) => x?.split(":")[0]);
  return [...new Set<string>(modules)];
};
