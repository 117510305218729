import { Col, Popconfirm, Row, Table } from "antd";
import {
  CodebookApi,
  CodebookApiApiCodebookByTypeGetRequest,
  CodebookTypeApi,
} from "api/api";
import {
  CodebookDto,
  CodebookDtoPaginatedList,
  CodebookTypeDto,
} from "api/models";
import { Button } from "components/buttons/buttons";
import { Cards } from "components/cards/frame/cards-frame";
import { PageHeader } from "components/page-headers/page-headers";
import {
  CardToolbox,
  Main,
  ProfilePageHeaderStyle,
  ProfileTableStyleWrapper,
  TableWrapper,
} from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { CreateCommonCodebookModal } from "pages/codebooks/CodebookCommonPage/CreateCommonCodebookModal";
import {
  codebookClaimNames,
  getCodebookTextsByType,
} from "pages/codebooks/CodebookCommonPage/data";
import openNotificationWithIcon from "utility/notification";
import { ColumnsType } from "antd/lib/table";
import { sortDirections } from "constants/constants";
import { useTableSorting } from "hooks/useTableSorting";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import { CreateCodebookTypeModal } from "./CreateCodebookTypeModal";

const codebookApi = new CodebookTypeApi();

type StateType = {
  addModalVisible?: boolean;
  itemForEditModal?: CodebookTypeDto | null;
  currentOrder: Number | null;
};
export const CodebookTypePage = () => {
  const { t } = useTranslation();
  const [codebookTypeData, setCodebookTypeData] = useState<CodebookTypeDto[]>(
    []
  );
  const [codebookItemsLoading, setCodebookItemsLoading] = useState(false);
  const [modalsState, setModalsState] = useState<StateType>({
    addModalVisible: false,
    itemForEditModal: null,
    currentOrder: null,
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchCodebookTypeItems();
  }, []);

  const fetchCodebookTypeItems = async () => {
    try {
      setCodebookItemsLoading(true);

      const response = await codebookApi.apiCodebookTypesGet();

      setCodebookTypeData(response.data);
    } catch (error) {
    } finally {
      setCodebookItemsLoading(false);
    }
  };

  const handleAddClick = () => {
    setModalsState({
      addModalVisible: true,
      currentOrder: codebookTypeData.length + 1,
    });
  };

  const handleEditClick = (item: CodebookDto) => {
    setModalsState({
      itemForEditModal: item,
      currentOrder: null,
    });
  };

  const handleSuccessAddOrEdit = async () => {
    closeModal();
    await fetchCodebookTypeItems();
  };

  const closeModal = () => {
    setModalsState({
      addModalVisible: false,
      itemForEditModal: null,
      currentOrder: null,
    });
  };

  const handleDeleteConfirm = async (id: string | undefined) => {
    try {
      if (!id) return;
      await codebookApi.apiCodebookTypesIdDelete({ id });
      openNotificationWithIcon(
        "success",
        t(`codebook:success-deleted"`, `Codebook deleted successfully!`)
      );
      await fetchCodebookTypeItems();
    } catch (err) {}
  };

  const columns: ColumnsType<any> = [
    {
      title: t("global.order", "Order"),
      dataIndex: "order",
      key: "order",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.code", "Code"),
      dataIndex: "code",
      key: "code",
      sorter: true,
      sortDirections,
    },
    {
      title: t("global.actions", "Actions"),
      dataIndex: "action",
      key: "action",
      width: "90px",
    },
  ];

  const tableData = (codebookTypeData ?? []).map((item) => {
    const { id, name, order,code } = item;

    return {
      key: id,
      name,
      order,
      code,
      action: (
        <div
          key={id}
          className="table-actions"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button className="btn-icon" type="primary" to="#" shape="circle">
            <Link onClick={() => handleEditClick(item)} to="#">
              <FeatherIcon icon="edit" size={16} />
            </Link>
          </Button>
          {hasPermission(`codebook-types:delete`) ? (
            <>
              <Popconfirm
                title={t(
                  "codebook:delete-confirmation",
                  "Please confirm if you want to permanently delete this"
                )}
                onConfirm={() => handleDeleteConfirm(id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className="btn-icon"
                  type="danger"
                  to="#"
                  shape="circle"
                >
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
            </>
          ) : null}
        </div>
      ),
    };
  });

  return (
    <>
      <CardToolbox>
        <ProfilePageHeaderStyle>
          <PageHeader
            ghost
            title={t("codebooks:title", "Common Lists")}
            buttons={[
              <ExportButtonPageHeader
                key="1"
                title={t("codebooks:title", "Common Lists")}
                data={tableData}
                columns={["name"]}
              />,
              hasPermission(`codebook-types:add`) && (
                <Button
                  onClick={handleAddClick}
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="add-codebook"
                >
                  {t("codebooks:add", "Add new Common List")}
                </Button>
              ),
            ]}
          />
        </ProfilePageHeaderStyle>
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <ProfileTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    <Table
                      loading={codebookItemsLoading}
                      dataSource={tableData}
                      columns={columns}
                      showSorterTooltip={false}
                      rowKey="key"
                      pagination={{
                        showSizeChanger: false,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                    />
                  </TableWrapper>
                </div>
              </ProfileTableStyleWrapper>
            </Cards>
          </Col>
        </Row>
      </Main>

      <CreateCodebookTypeModal
        isVisible={Boolean(modalsState.addModalVisible)}
        onHide={closeModal}
        onSuccess={handleSuccessAddOrEdit}
        currentOrder={modalsState.currentOrder}
      />
      <CreateCodebookTypeModal
        isVisible={Boolean(modalsState.itemForEditModal)}
        onHide={closeModal}
        onSuccess={handleSuccessAddOrEdit}
        codebookTypeToEdit={modalsState.itemForEditModal}
        currentOrder={modalsState.currentOrder}
      />
    </>
  );
};
