import ForgotPassword from "pages/authentication/ForgotPassword";
import ResetPasswordPage from "pages/authentication/ResetPasswordPage";
import SetNewPasswordPage from "pages/authentication/SetNewPasswordPage";
import SignIn from "pages/authentication/SignIn";
import NotFound from "pages/authorization/not-found/not-found";
import UnAuthorized from "pages/authorization/unAuthorized/unAuthorized";
import { CodebookCommonPage } from "pages/codebooks/CodebookCommonPage/CodebookCommonPage";
import EmailPage from "pages/localization/email/EmailPage";
import SystemLanguages from "pages/localization/system-languages/SystemLanguages";
import Translations from "pages/localization/translations/Translations";
import LogsPage from "pages/logs-audit/LogsPage";
import ReportsPage from "pages/reports/ReportsPage";
import Settings from "pages/settings/Settings";
import UserGroupPage from "pages/user-groups/UserGroupPage";
import CreateUserPage from "pages/users/CreateUserPage";
import UpdateUserPage from "pages/users/UpdateUserPage";
import UsersPage from "pages/users/UsersPage";
import { Navigate, RouteProps } from "react-router-dom";
import { hasPermission } from "utility/accessibility/hasPermission";
import ComingSoon from "pages/coming-soon/ComingSoon";
import { CodebookTypePage } from "pages/codebooks/CodebookTypePage/CodebookTypePage";
import BankruptcyPage from "pages/bankruptcy/BankruptcyPage";
import DownloadedCertificatePage from "pages/certificate-module/DownloadedCertificatePage";
// import { DisputedCertificatePage } from "pages/certificate-module/DisputedCertificatePage";
import GenerateCertificatePage from "pages/business-module/GenerateCertificatePage";
import CertificateDownloadedHistoryPage from "pages/business-module/CertificateDownloadedHistoryPage";
import CertificateValidationCheckerPage from "pages/certificate-validation-checker/CertificateValidationCheckerPage";
import { ReasonsConfigurationsPage } from "pages/reasons-configurations/ReasonsConfigurationsPage";

export type CustomRouteProps = {
  hasAccess: () => boolean;
} & RouteProps;

export const MainRoutes: CustomRouteProps[] = [
  {
    index: true,
    path: "/admin/",
    element: <ComingSoon />,
    hasAccess: () => true,
  },
  {
    path: "/",
    element: <Navigate to="/admin" replace />,
    hasAccess: () => true,
  },
  {
    index: false,
    path: "/admin/languages",
    element: <SystemLanguages />,
    hasAccess: () => hasPermission("languages:list"),
  },
  {
    index: true,
    path: "/admin/users/:id/edit",
    element: <UpdateUserPage />,
    hasAccess: () => hasPermission("users:add"),
  },
  {
    index: false,
    path: "/admin/users/create",
    element: <CreateUserPage />,
    hasAccess: () => hasPermission("users:add"),
  },
  {
    index: false,
    path: "/admin/users",
    element: <UsersPage />,
    hasAccess: () => hasPermission("users:list"),
  },
  {
    index: false,
    path: "/admin/user-groups",
    element: <UserGroupPage />,
    hasAccess: () => hasPermission("user-groups:list"),
  },
  {
    index: false,
    path: "/admin/logs",
    element: <LogsPage />,
    hasAccess: () => hasPermission("logs:list"),
  },
  {
    index: false,
    path: "/admin/reports",
    element: <ReportsPage />,
    hasAccess: () => true,
  },
  {
    index: false,
    path: "/admin/unauthorized",
    element: <UnAuthorized />,
    hasAccess: () => true,
  },
  {
    index: false,
    path: "/admin/profile",
    element: <Settings />,
    hasAccess: () => true,
  },
  {
    path: "/admin/translations",
    element: <Translations />,
    hasAccess: () => hasPermission("translations:list"),
  },
  {
    path: "/admin/reasons-configurations",
    element: <ReasonsConfigurationsPage />,
    hasAccess: () => hasPermission("reasons-configurations:list"),
  },
  {
    path: "/admin/codebook-types",
    element: <CodebookTypePage />,
    hasAccess: () => true || hasPermission("codebook-types:list"),
  },
  {
    path: "/admin/codebook-types/:id",
    element: <CodebookCommonPage />,
    hasAccess: () => true || hasPermission("codebook-types:list"),
  },
  {
    path: "/admin/businesses/bankrupted",
    element: <BankruptcyPage />,
    hasAccess: () => hasPermission("bankruptcy-cases:list"),
  },
  {
    path: "/admin/certificates/downloaded",
    element: <DownloadedCertificatePage />,
    hasAccess: () => hasPermission("certificates:view"),
  },
  {
    path: "/admin/email",
    element: <EmailPage />,
    hasAccess: () => hasPermission("email-options:add"),
  },
  {
    index: false,
    path: "*",
    element: <NotFound />,
    hasAccess: () => true,
  },
  {
    index: true,
    path: "/admin/certificates/generate",
    element: <GenerateCertificatePage />,
    hasAccess: () => hasPermission("certificates:generate"),
  },
];

export const AuthRoutes: RouteProps[] = [
  {
    index: false,
    path: "/login",
    element: <SignIn />,
  },
  {
    index: false,
    path: "/set-new-password",
    element: <SetNewPasswordPage />,
  },
  {
    index: false,
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    index: false,
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
];

export const PublicRoutes: RouteProps[] = [
  {
    index: true,
    path: "/certificate/view/:serialNumber",
    element: <CertificateValidationCheckerPage />,
  },
];
