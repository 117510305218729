/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcy App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddReasonConfigurationCommand } from '../models';
// @ts-ignore
import { ReasonsConfigurationsDto } from '../models';
// @ts-ignore
import { RemoveReasonConfigurationCommand } from '../models';
/**
 * ReasonsConfigurationsApi - axios parameter creator
 * @export
 */
export const ReasonsConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RemoveReasonConfigurationCommand} [removeReasonConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReasonsConfigurationsDelete: async (removeReasonConfigurationCommand?: RemoveReasonConfigurationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reasons-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeReasonConfigurationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReasonsConfigurationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reasons-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddReasonConfigurationCommand} [addReasonConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReasonsConfigurationsPost: async (addReasonConfigurationCommand?: AddReasonConfigurationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/reasons-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addReasonConfigurationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReasonsConfigurationsApi - functional programming interface
 * @export
 */
export const ReasonsConfigurationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReasonsConfigurationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RemoveReasonConfigurationCommand} [removeReasonConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReasonsConfigurationsDelete(removeReasonConfigurationCommand?: RemoveReasonConfigurationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReasonsConfigurationsDelete(removeReasonConfigurationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReasonsConfigurationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReasonsConfigurationsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReasonsConfigurationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddReasonConfigurationCommand} [addReasonConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReasonsConfigurationsPost(addReasonConfigurationCommand?: AddReasonConfigurationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReasonsConfigurationsPost(addReasonConfigurationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReasonsConfigurationsApi - factory interface
 * @export
 */
export const ReasonsConfigurationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReasonsConfigurationsApiFp(configuration)
    return {
        /**
         * 
         * @param {RemoveReasonConfigurationCommand} [removeReasonConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReasonsConfigurationsDelete(removeReasonConfigurationCommand?: RemoveReasonConfigurationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiReasonsConfigurationsDelete(removeReasonConfigurationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReasonsConfigurationsGet(options?: any): AxiosPromise<Array<ReasonsConfigurationsDto>> {
            return localVarFp.apiReasonsConfigurationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddReasonConfigurationCommand} [addReasonConfigurationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReasonsConfigurationsPost(addReasonConfigurationCommand?: AddReasonConfigurationCommand, options?: any): AxiosPromise<string> {
            return localVarFp.apiReasonsConfigurationsPost(addReasonConfigurationCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiReasonsConfigurationsDelete operation in ReasonsConfigurationsApi.
 * @export
 * @interface ReasonsConfigurationsApiApiReasonsConfigurationsDeleteRequest
 */
export interface ReasonsConfigurationsApiApiReasonsConfigurationsDeleteRequest {
    /**
     * 
     * @type {RemoveReasonConfigurationCommand}
     * @memberof ReasonsConfigurationsApiApiReasonsConfigurationsDelete
     */
    readonly removeReasonConfigurationCommand?: RemoveReasonConfigurationCommand
}

/**
 * Request parameters for apiReasonsConfigurationsPost operation in ReasonsConfigurationsApi.
 * @export
 * @interface ReasonsConfigurationsApiApiReasonsConfigurationsPostRequest
 */
export interface ReasonsConfigurationsApiApiReasonsConfigurationsPostRequest {
    /**
     * 
     * @type {AddReasonConfigurationCommand}
     * @memberof ReasonsConfigurationsApiApiReasonsConfigurationsPost
     */
    readonly addReasonConfigurationCommand?: AddReasonConfigurationCommand
}

/**
 * ReasonsConfigurationsApi - object-oriented interface
 * @export
 * @class ReasonsConfigurationsApi
 * @extends {BaseAPI}
 */
export class ReasonsConfigurationsApi extends BaseAPI {
    /**
     * 
     * @param {ReasonsConfigurationsApiApiReasonsConfigurationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReasonsConfigurationsApi
     */
    public apiReasonsConfigurationsDelete(requestParameters: ReasonsConfigurationsApiApiReasonsConfigurationsDeleteRequest = {}, options?: AxiosRequestConfig) {
        return ReasonsConfigurationsApiFp(this.configuration).apiReasonsConfigurationsDelete(requestParameters.removeReasonConfigurationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReasonsConfigurationsApi
     */
    public apiReasonsConfigurationsGet(options?: AxiosRequestConfig) {
        return ReasonsConfigurationsApiFp(this.configuration).apiReasonsConfigurationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReasonsConfigurationsApiApiReasonsConfigurationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReasonsConfigurationsApi
     */
    public apiReasonsConfigurationsPost(requestParameters: ReasonsConfigurationsApiApiReasonsConfigurationsPostRequest = {}, options?: AxiosRequestConfig) {
        return ReasonsConfigurationsApiFp(this.configuration).apiReasonsConfigurationsPost(requestParameters.addReasonConfigurationCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
