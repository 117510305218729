import { useEffect, useState } from "react";
import { Table, Checkbox, Col, Row, Form, Input } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ReasonsConfigurationsApi } from "api/api";
import { ReasonsConfigurationsDto } from "api/models";
import {
  CardToolbox,
  Main,
  ProfileTableStyleWrapper,
  TableWrapper,
  AddProfile,
  BasicFormWrapper,
} from "container/styled";
import { useTranslation } from "react-i18next";
import { PageHeader } from "components/page-headers/page-headers";
import openNotificationWithIcon from "utility/notification";
import { Modal } from "components/modals/antd-modals";
import { Button } from "components/buttons/buttons";

const reasonsConfigurationsApi = new ReasonsConfigurationsApi();

export const ReasonsConfigurationsPage = () => {
  const { t } = useTranslation();
  const [reasonsConfigurationsData, setReasonsConfigurationsData] = useState<
    ReasonsConfigurationsDto[]
  >([]);
  const [filteredData, setFilteredData] = useState<ReasonsConfigurationsDto[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentReasonId, setCurrentReasonId] = useState<string | undefined>(
    undefined
  );

  const [form] = Form.useForm();

  useEffect(() => {
    fetchReasonsConfigurations();
  }, []);

  useEffect(() => {
    setFilteredData(reasonsConfigurationsData);
  }, [reasonsConfigurationsData]);

  const fetchReasonsConfigurations = async () => {
    try {
      setLoading(true);
      const response =
        await reasonsConfigurationsApi.apiReasonsConfigurationsGet();
      setReasonsConfigurationsData(response.data);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Error fetching reasons configurations"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = async (item: any) => {
    if (item.isChecked) {
      // If already checked, remove the reason configuration
      try {
        await reasonsConfigurationsApi.apiReasonsConfigurationsDelete({
          removeReasonConfigurationCommand: { reasonId: item.key },
        });
        openNotificationWithIcon(
          "success",
          "Configuration removed successfully"
        );
        updateItem(item.key, false);
      } catch (error) {
        openNotificationWithIcon("error", "Error removing configuration");
      }
    } else {
      // If not checked, open the modal for input
      setCurrentReasonId(item.key);
      setModalVisible(true);
    }
  };

  const updateItem = (reasonId: string | undefined, isChecked: boolean) => {
    setReasonsConfigurationsData((prevData) =>
      prevData.map((item) =>
        item.id === reasonId ? { ...item, isChecked } : item
      )
    );
  };

  const handleSubmit = async (values: any) => {
    const { displayTextSQ, displayTextSR, displayTextEN } = values;

    if (!displayTextSQ || !displayTextSR || !displayTextEN) {
      openNotificationWithIcon(
        "error",
        "Please ensure reasons are provided for all three languages (Albanian, Serbian, and English) before submitting."
      );
      return;
    }

    try {
      setLoading(true);

      await reasonsConfigurationsApi.apiReasonsConfigurationsPost({
        addReasonConfigurationCommand: {
          reasonId: currentReasonId,
          displayTextSQ,
          displayTextSR,
          displayTextEN,
        },
      });

      openNotificationWithIcon("success", "Reason configuration has been successfully updated");
      setModalVisible(false);
      form.resetFields();
      updateItem(currentReasonId, true);
    } catch (error) {
      openNotificationWithIcon("error", "An error occurred while updating the reason configuration");
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: t("global.name", "Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t(
        "reasonsConfigurations.checkForBankruptcy",
        "Check for bankruptcy in last two years"
      ),
      dataIndex: "isChecked",
      key: "isChecked",
      align: "left",
      render: (text: any, record: any) => (
        <Checkbox
          checked={record.isChecked}
          onChange={() => handleToggle(record)}
        />
      ),
    },
  ];

  const tableData = filteredData.map((item) => ({
    key: item.id,
    name: item.name,
    isChecked: item.isChecked,
  }));

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("reasonsConfigurations:title", "Reasons Configurations")}
          subTitle={
            <div>
              <Input
                style={{ height: "38px" }}
                onChange={(param) => {
                  const searchValue = param.target.value.trim().toLowerCase();
                  setFilteredData(
                    reasonsConfigurationsData.filter((item) =>
                      item.name?.toLowerCase().includes(searchValue)
                    )
                  );
                }}
                placeholder={t(
                  "reasonsConfigurations.search-by",
                  "Search by Name"
                )}
                width="100%"
              />
            </div>
          }
        />
      </CardToolbox>
      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <ProfileTableStyleWrapper>
              <TableWrapper className="table-responsive">
                <Table
                  loading={loading}
                  dataSource={tableData}
                  columns={columns}
                  rowKey="key"
                  pagination={false}
                />
              </TableWrapper>
            </ProfileTableStyleWrapper>
          </Col>
        </Row>
      </Main>

      <Modal
        type={"primary"}
        title={t(
          "reasonsConfigurations:editModalTitle",
          "Edit Reason Configuration"
        )}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="project-modal">
          <AddProfile>
            <BasicFormWrapper>
              <Form
                form={form}
                name="reasonConfiguration"
                onFinish={handleSubmit}
                initialValues={{
                  displayTextSQ: "",
                  displayTextSR: "",
                  displayTextEN: "",
                }}
              >
                <Form.Item
                  name="displayTextSQ"
                  label={t(
                    "reasonsConfigurations.displayTextSQ",
                    "Display Text SQ"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t("validations.required-field", {
                        field: t(
                          "reasonsConfigurations.displayTextSQ",
                          "Display Text SQ"
                        ),
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      "reasonsConfigurations.displayTextSQ",
                      "Display Text SQ"
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="displayTextSR"
                  label={t(
                    "reasonsConfigurations.displayTextSR",
                    "Display Text SR"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t("validations.required-field", {
                        field: t(
                          "reasonsConfigurations.displayTextSR",
                          "Display Text SR"
                        ),
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      "reasonsConfigurations.displayTextSR",
                      "Display Text SR"
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="displayTextEN"
                  label={t(
                    "reasonsConfigurations.displayTextEN",
                    "Display Text EN"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t("validations.required-field", {
                        field: t(
                          "reasonsConfigurations.displayTextEN",
                          "Display Text EN"
                        ),
                      }),
                    },
                  ]}
                >
                  <Input
                    placeholder={t(
                      "reasonsConfigurations.displayTextEN",
                      "Display Text EN"
                    )}
                  />
                </Form.Item>

                <Row justify="end">
                  <Button
                    htmlType="submit"
                    size="default"
                    loading={loading}
                    type="primary"
                    key="submit"
                  >
                    {t("global.submit", "Submit")}
                  </Button>
                </Row>
              </Form>
            </BasicFormWrapper>
          </AddProfile>
        </div>
      </Modal>
    </>
  );
};
