/* tslint:disable */
/* eslint-disable */
/**
 * eBankruptcy App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PaginatedBankruptcyCasesDto } from '../models';
/**
 * BankruptcyCasesApi - axios parameter creator
 * @export
 */
export const BankruptcyCasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBankruptcyCasesGet: async (searchTerm?: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bankruptcy-cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['SearchTerm'] = searchTerm;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankruptcyCasesApi - functional programming interface
 * @export
 */
export const BankruptcyCasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankruptcyCasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBankruptcyCasesGet(searchTerm?: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBankruptcyCasesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBankruptcyCasesGet(searchTerm, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankruptcyCasesApi - factory interface
 * @export
 */
export const BankruptcyCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankruptcyCasesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [searchTerm] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBankruptcyCasesGet(searchTerm?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedBankruptcyCasesDto> {
            return localVarFp.apiBankruptcyCasesGet(searchTerm, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiBankruptcyCasesGet operation in BankruptcyCasesApi.
 * @export
 * @interface BankruptcyCasesApiApiBankruptcyCasesGetRequest
 */
export interface BankruptcyCasesApiApiBankruptcyCasesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof BankruptcyCasesApiApiBankruptcyCasesGet
     */
    readonly searchTerm?: string

    /**
     * 
     * @type {number}
     * @memberof BankruptcyCasesApiApiBankruptcyCasesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof BankruptcyCasesApiApiBankruptcyCasesGet
     */
    readonly pageSize?: number
}

/**
 * BankruptcyCasesApi - object-oriented interface
 * @export
 * @class BankruptcyCasesApi
 * @extends {BaseAPI}
 */
export class BankruptcyCasesApi extends BaseAPI {
    /**
     * 
     * @param {BankruptcyCasesApiApiBankruptcyCasesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankruptcyCasesApi
     */
    public apiBankruptcyCasesGet(requestParameters: BankruptcyCasesApiApiBankruptcyCasesGetRequest = {}, options?: AxiosRequestConfig) {
        return BankruptcyCasesApiFp(this.configuration).apiBankruptcyCasesGet(requestParameters.searchTerm, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}
