import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FeatherIcon from "feather-icons-react";

import { Button } from "components/buttons/buttons";
import { PageHeader } from "components/page-headers/page-headers";
import { useIsMobileDevice } from "hooks/useIsMobileDevice";
import { useParams } from "react-router-dom";
import { CertificateApi } from "api/api";

import { CardToolbox, ProfilePageheaderStyle } from "container/styled";
import {
  Container,
  ButtonContainer,
} from "pages/certificate-validation-checker/style";
import { useDownloadBase64AsPdf } from "hooks/useDownloadBase64AsPdf";
import { Spin } from "antd";

const certificateApi = new CertificateApi();

const CertificateValidationCheckerPage = () => {
  /**
   * Translation
   */
  const { t } = useTranslation();
  const params = useParams();
  const serialNumber = params.serialNumber;

  const [pdfBlobUrl, setPdfBlobUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = useIsMobileDevice();
  const downloadBase64AsPdf = useDownloadBase64AsPdf();

  useEffect(() => {
    console.log(serialNumber, decodeURIComponent(serialNumber as string));

    if (!serialNumber) {
      setPdfBlobUrl(null);
      return;
    }
    const fetchPdfData = async () => {
      setIsLoading(true);
      try {
        const languageCode = localStorage.getItem("i18nextLng");
        const response = await certificateApi.apiCertificateValidatePost({
          validateCertificateCommand: {
            languageCode,
            serialNumber: decodeURIComponent(serialNumber),
          },
        });
        const base64 = response.data as any;
        setPdfBlobUrl(base64.pdfBase64);
      } catch (error) {
        console.error("Error fetching or processing the PDF: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPdfData();
  }, [serialNumber]);

  const handleViewPDF = () =>
    isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <Spin />
      </div>
    ) : (
      <div style={{ height: "85vh" }}>
        <embed
          src={`data:application/pdf;base64,${pdfBlobUrl}`}
          width={"100%"}
          height={"100%"}
        />
      </div>
    );

  const showDownloadCertificateButton = () => (
    <ButtonContainer>
      <Button
        type="primary"
        size="large"
        loading={isLoading}
        disabled={isLoading}
        onClick={() =>
          downloadBase64AsPdf(
            pdfBlobUrl,
            `Gjykata-Komerciale-${new Date().getDate()}-${
              new Date().getMonth() + 1
            }-${new Date().getFullYear()}.pdf`
          )
        }
      >
        <FeatherIcon icon="download" size="24" />
        {t(
          "certificate-validation-checker:button.download",
          "Download Certificate"
        )}
      </Button>
    </ButtonContainer>
  );

  return (
    <>
      <CardToolbox>
        <ProfilePageheaderStyle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "rgb(244, 245, 247)",
          }}
        >
          <PageHeader
            ghost
            title={t(
              "certificate-validation-checker:title",
              "Certificate Validation Checker"
            )}
          />
        </ProfilePageheaderStyle>
      </CardToolbox>
      <Container>
        {/* <ErrorContainer>This certificate is invalid and should not be used!</ErrorContainer> */}
        {isMobile ? showDownloadCertificateButton() : handleViewPDF()}
      </Container>
    </>
  );
};

export default CertificateValidationCheckerPage;
