import { Form, Input, Row } from "antd";
import { CodebookApi } from "api/api";
import { Button } from "components/buttons/buttons";
import { Modal } from "components/modals/antd-modals";
import { AddProfile, BasicFormWrapper } from "container/styled";
import { getCodebookTextsByType } from "pages/codebooks/CodebookCommonPage/data";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import openNotificationWithIcon from "utility/notification";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { CodebookDto } from "api/models";

interface CreateCommonCodebookModalProps {
  codebookTypeId: any;
  codebookType: any;
  isVisible: boolean;
  codebookToEdit?: CodebookDto | null;
  onHide: () => void;
  onSuccess: () => void;
}

const codebookApi = new CodebookApi();

const defaultValues = {
  name: "",
};

export const CreateCommonCodebookModal = ({
  codebookType,
  codebookTypeId,
  isVisible,
  onHide,
  onSuccess,
  codebookToEdit,
}: CreateCommonCodebookModalProps) => {
  const { t } = useTranslation();
  const {
    addModalTitle,
    editModalTitle,
    editedSuccessfully,
    addedSuccessfully,
  } = getCodebookTextsByType(t, codebookType);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const isEditMode = Boolean(codebookToEdit);

  useEffect(() => {
    if (!codebookToEdit) {
      return;
    }

    const { id, name, code } = codebookToEdit;

    form.setFieldsValue({
      id,
      name,
      code,
    });
  }, [codebookToEdit, form]);

  const handleSubmit = async (values: any) => {
    try {
      const { id, name, code } = values;
      setLoading(true);

      if (isEditMode) {
        await codebookApi.apiCodebookIdPut({
          id,
          updateCodebookCommand: { id, name, code, codebookTypeId },
        });
      } else {
        await codebookApi.apiCodebookPost({
          addCodebookCommand: { name, codebookTypeId, code },
        });
      }

      openNotificationWithIcon(
        "success",
        isEditMode ? editedSuccessfully : addedSuccessfully
      );

      form.resetFields();
      onSuccess();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      type={"primary"}
      title={isEditMode ? editModalTitle : addModalTitle}
      visible={isVisible}
      onCancel={onHide}
      footer={null}
    >
      <div className="project-modal">
        <AddProfile>
          <BasicFormWrapper>
            <Form
              requiredMark
              form={form}
              name={isEditMode ? "editInstitution" : "addInstitution"}
              onFinish={handleSubmit}
              initialValues={defaultValues}
            >
              <Form.Item name="id" hidden>
                <Input placeholder="Id" />
              </Form.Item>

              <Form.Item
                required
                requiredMark
                rules={[
                  {
                    required: true,
                    message: t("validations.required-field", {
                      field: t("global.name", "Name"),
                      defaultValue: "{{field}} is required!",
                    }),
                  },
                ]}
                name={"name"}
                label={t("global.name", "Name")}
              >
                <Input
                  required
                  placeholder={t("global.name", "Name") + "*"}
                  prefix={<FeatherIcon icon="user" size={14} />}
                />
              </Form.Item>

              <Form.Item
                required
                requiredMark
                rules={[
                  {
                    required: true,
                    message: t("validations.required-field", {
                      field: t("global.code", "Code"),
                      defaultValue: "{{field}} is required!",
                    }),
                  },
                ]}
                name={"code"}
                label={t("global.code", "Code")}
              >
                <Input
                  required
                  placeholder={t("global.code", "Code") + "*"}
                  prefix={<FeatherIcon icon="user" size={14} />}
                />
              </Form.Item>

              <Row justify="end">
                <Button
                  htmlType="submit"
                  size="default"
                  loading={loading}
                  type="primary"
                  key="submit"
                >
                  {isEditMode
                    ? t("global:edit", "Edit")
                    : t("global:add", "Add")}
                </Button>
              </Row>
            </Form>
          </BasicFormWrapper>
        </AddProfile>
      </div>
    </Modal>
  );
};
